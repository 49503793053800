<template>
  <div class="container">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="qwiz.tv">
          <img src="../../../assets/logo.png" height="50">
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <a class="button is-light" @click="$router.push({ name: 'Logout' })">
                Log out
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="columns">
      <div class="column is-narrow">
        <admin-menu></admin-menu>    
      </div>
      <div class="column">
        <router-view></router-view>    
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenu from '../menu/Index.vue';

export default {
  name: 'admin-layout',
  components: { AdminMenu },
};
</script>

<style scoped lang="scss">
</style>
