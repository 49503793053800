<template>
  <div>
    <h1 class="title is-2">
      Gestion du contenus des pages de texte
    </h1>

    <b-table
      :data="contents"
    >
      <b-table-column field="id" label="ID" v-slot="props">
        <router-link :to="{ name: 'ContentEdit', params: { contentId: props.row.id }}">
          {{ props.row.id }}
        </router-link>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'admin-contents',
  beforeCreate() {
    this.$store.dispatch('contents/fetchAll');
  },
  computed: {
    contents() {
      return this.$store.state.contents;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
