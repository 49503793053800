<template>
  <div>
    <h1 class="title is-2">
      Liste des séances
      <button class="button is-primary is-small" @click="$router.push({ name: 'NewGame' })">
        Ajouter
      </button>
    </h1>

    <b-table
      :data="games"
    >
      <b-table-column field="title" label="Titre" v-slot="props">
        <router-link :to="{ name: 'GameEdit', params: { gameId: props.row.id }}">
          {{ props.row.title }}
        </router-link>
      </b-table-column>

      <b-table-column field="start" label="Date / heure" v-slot="props">
        {{ props.row.start | date }}
      </b-table-column>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Aucune game selon la sélection trouvée!</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
export default {
  name: 'admin-games',
  beforeCreate() {
    this.$store.dispatch('games/fetch');
  },
  filters: {
    date(date) {
      return DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_FULL);
    },
  },
  computed: {
    games() {
      return this.$store.state.games;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
