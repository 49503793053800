<template>
  <div>
    <button class="button is-small" @click="getScreenshots">Refresh screenshots</button>
    <img v-for="(screenshot, index) in screenshots" :key="index" :src="src(screenshot)" />
  </div>
</template>

<script>
import firebase from 'firebase/app';

export default {
  name: 'admin-tests-screenshots',
  data() {
    return {
      screenshots: [],
    };
  },
  methods: {
    async getScreenshots() {
      this.screenshots = [];

      const db = firebase.firestore();

      const snapshot = await db
        .collection('screenshots')
        .get();

      if (snapshot.empty) {
        return;
      }  
      
      snapshot.forEach(doc => {
        this.screenshots.push({ ...doc.data(), id: doc.id });
      });
    },
    src(screenshot) {
      return `data:image/jpeg;base64, ${screenshot.image}`;
    },
  }
};
</script>

<style scoped lang="scss">
  img {
    max-height: 100px;
  }
</style>
