<template>
  <div>
    <h1 class="title is-2">{{ formTitle }}</h1>

    <div class="field">
      <label class="label">Contenu de la page</label>
      <div class="control">
        <content-field v-model="content" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentField from '../content/Field';

export default {
  components: { ContentField },
  name: 'admin-contents-form',
  props: {
    contentId: {
      required: false,
      type: String,
      default: '',
    },
  },  
  data() {
    return {
      modifiedPayload: {},
      updating: false,
    };
  },
  created() {
    if (this.contentId) {
      this.$store.dispatch('contents/fetchById', this.contentId);
    }
  },
  computed: {
    formTitle() {
      return 'Édition page : ' + this.contentId;
    },
    currentObjectState() {
      if (this.contentId) {
        return this.$store.state.contents.find(g => g.id === this.contentId);
      }
      return {};
    },
    payload() {
      return { ...this.currentObjectState, ...this.modifiedPayload };
    },
    hasModifiedPayload() {
      return Object.entries(this.modifiedPayload).length > 0;
    },
    content: {
      get() {
        return this.payload.content;
      },
      async set(v) {
        this.$set(this.modifiedPayload, 'content', v);
        await this.save();
      },
    },
  },
  methods: {
    async save() {
      this.updating = true;
      const action = this.contentId ? 'update' : 'create';
      await this.$store.dispatch(`contents/${action}`, { 
        content: this.modifiedPayload, 
        id: this.contentId,
      });
      this.updating = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
