<template>
  <form action="" @click.prevent>
    <div class="modal-card full-screen">
      <header class="modal-card-head">
        <p class="modal-card-title">Édition JSON</p>
      </header>
      <section class="modal-card-body">
        <vue-json-editor 
          v-model="json" 
          mode="code"
          :show-btns="false" 
          :expandedOnStart="true"
          @json-change="update"
          @has-error="setError"
        ></vue-json-editor>      
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-primary"
          :disabled="hasError"
          @click="save"
        >Enregistrer</button>
        <button class="button" type="button" @click.prevent="close">Annuler</button>
      </footer>
    </div>
  </form>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'

export default {
  name: 'json-modal-editor',
  components: { vueJsonEditor },
  props: {
    value: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      hasError: false,
      updatedValue: null,
    };
  },
  computed: {
    json: {
      get() {
        return this.updatedValue || this.value;
      },
      set(v) {
        this.updatedValue = v;
      },
    }
  },
  methods: {
    async save() {
      this.$emit('input', this.json);
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
    update() {
      this.hasError = false;
    },
    setError() {
      this.hasError = true;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
