<template>
  <aside class="menu">
    <p class="menu-label">
      Programmation
    </p>
    <ul class="menu-list">
      <li>
        <router-link :to="{ name: 'Games' }">
          Séances
        </router-link>
      </li>
    </ul>
    <p class="menu-label">
      Administration
    </p>
    <ul class="menu-list">
      <li><a>Utilisateurs</a></li>
      <li>
        <router-link :to="{ name: 'Contents' }">
          Contenus
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Tests' }">
          Tests
        </router-link>
      </li>
    </ul>
    <p class="menu-label">
      Transactions
    </p>
    <ul class="menu-list">
      <li><a>Payments</a></li>
      <li><a>Transfers</a></li>
      <li><a>Balance</a></li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'admin-menu',
};
</script>

<style scoped lang="scss">
</style>
