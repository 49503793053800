<template>
  <form action="" @click.prevent>
    <div class="modal-card full-screen">
      <header class="modal-card-head">
        <p class="modal-card-title">Édition de contenu</p>
      </header>
      <section class="modal-card-body" id="editor">
        <vue-editor 
          v-model="content"
          :editorOptions="{}"
        ></vue-editor>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-primary"
          @click="save"
        >Enregistrer</button>
        <button class="button" type="button" @click.prevent="close">Annuler</button>
      </footer>
    </div>
  </form>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: 'content-modal-editor',
  components: { VueEditor },
  props: {
    value: {
      required: false,
      default: '',
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    async save() {
      this.$emit('input', this.content);
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
