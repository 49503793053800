<template>
  <div>
    <b-taginput
      :value="expandedSelection"
      :data="proposedUsers"
      field="displayName"
      autocomplete
      :allow-new="false"
      :maxtags="max"
      @typing="setFilter"
      @add="addUser"
      @remove="user => removeUser(user.id)"
    >
      <template v-slot="props">
        {{ props.option.displayName }} ({{ props.option.email }})
      </template>
      <template #tag="props">
        {{ props.tag.displayName }} ({{ props.tag.email }})
      </template>
    </b-taginput>

  </div>
</template>

<script>
export default {
  name: 'user-selector',
  props: {
    value: {
      required: false,
      default() {
        return [];
      },
    },
    max: {
      required: false,
      default: 999,
    }
  },  
  data() {
    return {
      filterText: null,
    };
  },
  beforeCreate() {
    this.$store.dispatch('users/fetch');
  },
  computed: {
    proposedUsers() {
      if (!this.filterText) return [];
      return this.allUsers
        .filter((user) => (user.displayName || '').toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0 ||
          (user.email || '').toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0)
        .filter((user) => !this.selection.find(s => s.id === user.id));
    },
    selection() {
      return this.value || [];
    },
    allUsers() {
      return this.$store.state.users.all;
    },
    expandedSelection() {
      return this.selection
        .map(({ id, choiceIndex, temp }) => {
          if (temp) return temp;
          const user = this.allUsers.find(u => u.id === id);
          if (!user) return null;
          return { ...user, choiceIndex };
        })
        .filter(x => !!x);
    },
  },
  methods: {
    async addUser(param) {
      this.updateValue([...this.selection, { id: param.id }]);
    },
    removeUser(userId) {
      const indexInSelection = this.selection.findIndex(s => s.id === userId);
      if (indexInSelection < 0) return;
      const newValue = [...this.selection];
      newValue.splice(indexInSelection, 1);
      this.updateValue(newValue);
    },
    updateValue(newValue) {
      this.$emit('input', newValue);
    },
    setFilter(text) {
      this.filterText = text;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
