<template>
  <div>
    <h1 class="title is-2">{{ formTitle }}</h1>

    <div class="field">
      <label class="label">Titre</label>
      <div class="control">
        <input class="input" type="text" placeholder="Titre de la séance" v-model="title">
      </div>
    </div>

    <div class="field">
      <label class="label">Slug</label>
      <div class="control">
        <input class="input" type="text" placeholder="Slug" v-model="slug">
      </div>
    </div>

    <div class="field">
      <label class="label">États</label>
      <div class="control">
        <b-switch v-model="published">
          Publié
        </b-switch> <br>
        <b-switch v-model="publicAccess">
          Accès public
        </b-switch>
      </div>
    </div>

    <div class="field">
      <label class="label">Date / heure de début</label>
      <div class="control">
        <b-datetimepicker
          placeholder="Date et heure"
          icon="calendar"
          v-model="start"
          horizontal-time-picker
          locale="fr-CA"
          :datepicker="{}"
          :datetime-formatter="datetimeFormatter"
        ></b-datetimepicker>
      </div>
    </div>

    <div class="field">
      <label class="label">Animateur</label>
      <div class="control">
        <user-selector :max="1" v-model="animators" />
      </div>
    </div>

    <div class="field">
      <label class="label">Producteurs</label>
      <div class="control">
        <user-selector v-model="producers" />
      </div>
    </div>

    <div class="field">
      <label class="label">Identifiant ronde musicale</label>
      <div class="control">
        <input class="input" type="text" placeholder="Indiquez l'identifiant de la ronde musicale" v-model="musicalRoundName">
      </div>
    </div>

    <div class="field">
      <label class="label">Groupes de médias utilisés</label>
      <div class="control">
        <input class="input" type="text" placeholder="Groupes de médias utilisés" v-model="mediaLocations">
        <div class="hint">
          Inscrivez les sous-répertoires directs du répertoire /game-medias dans Firebase Storage séparés par une virgule.
          Ex: base, musique, test
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label">Questions</label>
      <div class="control">
        Total: {{ questions.length }}
        <button 
          class="button is-small"
          @click="openJSONModal"
        >
          Modifier
        </button>
      </div>
    </div>

    <div class="field">
      <label class="label">Accroche</label>
      <div class="control">
        <content-field v-model="lead" />
      </div>
    </div>

    <div class="field">
      <label class="label">Contenu principal</label>
      <div class="control">
        <content-field v-model="content" />
      </div>
    </div>

    <div class="field">
      <label class="label">Hero Section Background CSS</label>
      <div class="control">
        <input class="input" type="text" placeholder="Indiquez un background" v-model="background">
      </div>
    </div>

    <div class="field">
      <label class="label">Mise à disposition</label>
      <div class="control">
        <div v-if="provisionFinished">
          <a :href="fullSubDomain" target="new" class="button is-success">Accès au quiz</a>
        </div>
        <div v-if="provisionOccuring">
          <span class="icon is-medium">
            <i class="fas fa-spinner fa-pulse"></i>
          </span>
        </div>
        <ul>
          <li 
            v-for="(provisionLogLine, index) in provision.logLines || []"
            :key="index"
          >
            <span class="padd" v-if="!provisionLogLine.isError">✅</span>
            <span class="padd" v-else>🛑</span>
            <span>{{ provisionLogLine.message }}</span>
          </li>
          <li v-if="provision.dropletName">
            Nom du droplet: {{ provision.dropletName }}
          </li>
          <li v-if="provision.subDomain">
            Sous-domaine: {{ provision.subDomain.id }}
          </li>
        </ul>
        
        <div>
          <b-switch v-model="highPerformance" v-if="!provisionStarted">
            Haute performance
          </b-switch> <br>

          <button 
            class="button is-warning"
            @click="startProvision"
            v-if="!provisionStarted"
          >
            Démarrer la mise à disposition
          </button>
        </div>

        <button 
          class="button is-danger"
          @click="endProvision"
          v-if="provisionStarted"
        >
          AZ-5
        </button>
      </div>
    </div>

    <div class="buttons">
      <button 
        class="button is-primary element"
        :class="updating && 'is-loading'"
        @click="save"
        :disabled="!hasModifiedPayload || updating"
      >
        Enregistrer
      </button>
      <button class="button" @click="$router.back()">
        Annuler
      </button>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import axios from 'axios';
import JSONModal from './JSONModal';
import UserSelector from './UserSelector.vue';
import apiBase from '../../../utils/api';
import ContentField from '../content/Field';

export default {
  components: { UserSelector, ContentField },
  name: 'admin-games-form',
  props: {
    gameId: {
      required: false,
      type: String,
      default: '',
    },
  },  
  data() {
    return {
      modifiedPayload: {},
      updating: false,
      highPerformance: false,
      datetimeFormatter: (date) => DateTime.fromJSDate(date).toLocaleString(DateTime.DATETIME_FULL),
      datePickerOptions: {},
    };
  },
  created() {
    if (this.gameId) {
      this.$store.dispatch('games/fetchById', { id: this.gameId, getPrivateData: true });
    }
  },
  computed: {
    fullSubDomain() {
      return `https://${this.provision.subDomain.id}.qwiz.tv`;
    },
    provisionStarted() {
      return Object.keys(this.provision).length > 0;
    },
    provisionOccuring() {
      return this.provisionStarted && !this.provision.finished;
    },
    provisionFinished() {
      return this.provision.finished;
    },
    formTitle() {
      return this.gameId !== '' && 'Édition séance' || 'Nouvelle séance';
    },
    currentObjectState() {
      if (this.gameId) {
        return this.$store.state.games.find(g => g.id === this.gameId);
      }
      return {};
    },
    payload() {
      return { ...this.currentObjectState, ...this.modifiedPayload };
    },
    hasModifiedPayload() {
      return Object.entries(this.modifiedPayload).length > 0;
    },
    slug: {
      get() {
        return this.payload.slug;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'slug', v);
      },
    },
    lead: {
      get() {
        return this.payload.lead;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'lead', v);
      },
    },
    content: {
      get() {
        return this.payload.content;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'content', v);
      },
    },
    background: {
      get() {
        return this.payload.background;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'background', v);
      },
    },
    publicAccess: {
      get() {
        return this.payload.publicAccess;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'publicAccess', v);
      },
    },
    published: {
      get() {
        return this.payload.published;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'published', v);
      },
    },
    mediaLocations: {
      get() {
        return this.payload.mediaLocations;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'mediaLocations', v);
      },
    },
    musicalRoundName: {
      get() {
        return this.payload.musicalRoundName;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'musicalRoundName', v);
      },
    },
    title: {
      get() {
        return this.payload.title;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'title', v);
      },
    },
    start: {
      get() {
        return this.payload.start;
      },
      set(v) {
        this.$set(this.modifiedPayload, 'start', v);
      },
    },
    questions: {
      get() {
        const privateItems = this.payload.privateItems || {};
        return (privateItems.questions) || [];
      },
      set(v) {
        const privateItems = this.payload.privateItems || {};
        privateItems.questions = v;
        this.$set(this.modifiedPayload, 'privateItems', privateItems);
      },
    },
    animators: {
      get() {
        return this.payload.animators || [];
      },
      set(v) {
        this.$set(this.modifiedPayload, 'animators', v);
      },
    },
    producers: {
      get() {
        return this.payload.producers || [];
      },
      set(v) {
        this.$set(this.modifiedPayload, 'producers', v);
      },
    },    
    provision() {
      return this.payload.provision || {}
    },
  },
  methods: {
    async save() {
      if (!this.payload.title) return this.$buefy.dialog.alert('Le titre doit être indiqué');

      this.updating = true;
      const action = this.gameId ? 'update' : 'create';
      const result = await this.$store.dispatch(`games/${action}`, { 
        game: this.modifiedPayload, 
        id: this.gameId,
      });
      this.updating = false;

      await axios.post(`${apiBase}/games/${this.gameId}/publish`);

      if (!this.gameId) {
        this.$router.push({ name: 'GameEdit', params: { gameId: result }});
      }
    },
    openJSONModal() {
      const comp = this;
      this.$buefy.modal.open({
        parent: this,
        props: {
          value: this.questions || [],
        },
        events: {
          input(x) {
            comp.questions = x;
          },
        },
        component: JSONModal,
        hasModalCard: true,
        trapFocus: true,
        fullScreen: true,
      });
    },
    async startProvision() {
      const query = this.highPerformance ? '?high=1' : '';
      const token = await this.$store.dispatch('auth/getIdToken');
      await axios.post(`${apiBase}/games/${this.gameId}/provision${query}`, null, {
        headers: { token },
      });
    },
    async endProvision() {
      const token = await this.$store.dispatch('auth/getIdToken');
      const result = await axios.post(`${apiBase}/games/${this.gameId}/provision/destroy`, null, {
        headers: { token },
      });
      const { log } = result.data;
      const message = log.join('<br>');
      this.$buefy.dialog.alert(message);
    },
  },
};
</script>

<style scoped lang="scss">
  .padd {
    padding-right: 0.5em;
  }
</style>
