<template>
  <table class="table">
    <tr>
      <td>
        ID
      </td>
      <td>
        started
      </td>
      <td>
        allLaunched
      </td>
      <td>
        allTerminated
      </td>
      <td>
        booting
      </td>
      <td>
        launched
      </td>
      <td>
        cant-get-feed      
      </td>
      <td>
        awaiting-snapshot
      </td>
      <td>
        closing
      </td>
      <td>
        terminated
      </td>
    </tr>
    <tr v-for="cell in cells" :key="cell.id">
      <td>
        {{ cell.id }}
      </td>
      <td>
        {{ rootVal('started', cell) }}
      </td>
      <td>
        {{ rootVal('allLaunched', cell) }}
      </td>
      <td>
        {{ rootVal('allTerminated', cell) }}
      </td>
      <td>
        {{ counterVal('booting', cell) }}
      </td>
      <td>
        {{ counterVal('launched', cell) }}
      </td>
      <td>
        {{ counterVal('cant-get-feed', cell) }}
      </td>
      <td>
        {{ counterVal('awaiting-snapshot', cell) }}
      </td>
      <td>
        {{ counterVal('closing', cell) }}
      </td>
      <td>
        {{ counterVal('terminated', cell) }}
      </td>
    </tr>
    <tr>
      <td>
      </td>
      <td>
      </td>
      <td>
      </td>
      <td>
      </td>
      <td>
        {{ sumVal('booting') }}
      </td>
      <td>
        {{ sumVal('launched') }}
      </td>
      <td>
        {{ sumVal('cant-get-feed') }}
      </td>
      <td>
        {{ sumVal('awaiting-snapshot') }}
      </td>
      <td>
        {{ sumVal('closing') }}
      </td>
      <td>
        {{ sumVal('terminated') }}
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'admin-tests-cells',
  created() {
    this.$store.dispatch('tests/fetch');
    this.$store.dispatch('tests/fetchCommand');
  },
  computed: {
    cells() {
      return this.$store.state.tests.items;
    },
    sums() {
      const keys = ['booting', 'launched', 'awaiting-snapshot', 'closing', 'terminated'];
      const sums = {};
      keys.forEach((key) => {
        this.cells.forEach((cell) => {
          const v = cell.counters[key];
          if (!v) return;
          if (!sums[key]) sums[key] = 0;
          sums[key] += v;
        });
      });
      return sums;
    },
  },
  methods: {
    rootVal(key, cell) {
      const v = cell[key];
      return v ? 'OUI' : '-';
    },
    counterVal(key, cell) {
      const v = cell.counters[key];
      return v ? v : '-';
    },
    sumVal(key) {
      const v = this.sums[key];
      return v ? v : '-';      
    },
  },
};
</script>

<style scoped lang="scss">
</style>
