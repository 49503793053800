<template>
  <div class="content-field">
    <button
      class="button is-small"
      @click="openModal"
    >
      Éditer
    </button>
    <div class="content" v-html="value"></div>
  </div>
</template>

<script>
import ContentModal from './Modal';

export default {
  name: 'content-field',
  props: {
    value: {
      required: false,
      default: '',
    },
  },
  methods: {
    openModal() {
      const comp = this;
      this.$buefy.modal.open({
        parent: this,
        props: {
          value: this.value,
        },
        events: {
          input(x) {
            comp.$emit('input', x);
          },
        },
        component: ContentModal,
        hasModalCard: true,
        trapFocus: true,
        fullScreen: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
