<template>
  <div>
    <h1 class="title is-2">
      Stress Tests
    </h1>

    {{ command }}
    <button class="button is-small" @click="sendCmd('launch')">launch</button>
    <button class="button is-small" @click="sendCmd('final')">final</button>
    <button class="button is-small" @click="sendCmd('abort')">abort</button>
    <button class="button is-small" @click="reset">reset</button>
    
    <admin-test-cells />
    <admin-test-screenshots />
  </div>
</template>

<script>
import AdminTestCells from './table';
import AdminTestScreenshots from './Screenshots';
import firebase from 'firebase/app';

export default {
  name: 'admin-tests',
  components: { AdminTestCells, AdminTestScreenshots },
  created() {
    this.$store.dispatch('tests/fetch');
    this.$store.dispatch('tests/fetchCommand');
  },
  computed: {
    command() {
      return this.$store.state.tests.command;
    },
    cells() {
      return this.$store.state.tests.items;
    },
  },
  methods: {
    sendCmd(command) {
      const db = firebase.firestore();
      const ref = db.collection('commands').doc('main');
      ref.update({ command });
    },
    async reset() {
      async function deleteAll(collection) {
        const db = firebase.firestore();
        const snapshot = await db.collection(collection).get();

        if (snapshot.empty) {
          return;
        }  
        
        const refs = [];
        snapshot.forEach(doc => {
          refs.push(db.collection(collection).doc(doc.id));
        });

        return Promise.all(refs.map((ref) => ref.delete()));
      }

      await deleteAll('cells');
      await deleteAll('screenshots');
    },
  }
};
</script>

<style scoped lang="scss">
</style>
